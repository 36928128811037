import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import AdminNavbar from '../adminNavbar';
import './OrderManagement.css';
import Skeleton from 'react-loading-skeleton';

const OrderManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null); 
  const [myorders, setMyOrders] = useState([]); 
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchOrdersAndProducts = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const ordersResponse = await axios.get('https://waveart.in/pay/getOrder');
        setMyOrders(ordersResponse.data.orderData || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchOrdersAndProducts();
  }, []);
//if you want to see all product 
  // useEffect(() => {
  //   if (Array.isArray(myorders)) {
  //     const filtered = myorders.filter((order) => {
  //       const orderId = order.order_id ? order.order_id.toLowerCase() : '';
  //       const productName = order.title ? order.title.toLowerCase() : '';
  //       const userName = order.name ? order.name.toLowerCase() : '';
  //       const userEmail = order.email ? order.email.toLowerCase() : '';
  //       const userPhone = order.phone ? order.phone.toLowerCase() : '';
  //       const orderDate = new Date(order.createdAt);

  //       const searchTermMatch =
  //         orderId.includes(searchTerm.toLowerCase()) ||
  //         productName.includes(searchTerm.toLowerCase()) ||
  //         userName.includes(searchTerm.toLowerCase()) ||
  //         userEmail.includes(searchTerm.toLowerCase()) ||
  //         userPhone.includes(searchTerm.toLowerCase());

  //       const dateMatch = !selectedDate || isSameDate(orderDate, new Date(selectedDate));

  //       return searchTermMatch && dateMatch;
  //     });

  //     const sortedFilteredOrders = filtered.sort(
  //       (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //     );
  //     setFilteredOrders(sortedFilteredOrders);
  //   } else {
  //     setFilteredOrders([]);
  //   }
  // }, [searchTerm, selectedDate, myorders]);

  //if you want to see 15 product in page 
  useEffect(() => {
    if (Array.isArray(myorders)) {
      const filtered = myorders.filter((order) => {
        const orderId = order.order_id ? order.order_id.toLowerCase() : '';
        const productName = order.title ? order.title.toLowerCase() : '';
        const userName = order.name ? order.name.toLowerCase() : '';
        const userEmail = order.email ? order.email.toLowerCase() : '';
        const userPhone = order.phone ? order.phone.toLowerCase() : '';
        const orderDate = new Date(order.createdAt);
  
        const searchTermMatch =
          orderId.includes(searchTerm.toLowerCase()) ||
          productName.includes(searchTerm.toLowerCase()) ||
          userName.includes(searchTerm.toLowerCase()) ||
          userEmail.includes(searchTerm.toLowerCase()) ||
          userPhone.includes(searchTerm.toLowerCase());
  
        const dateMatch = !selectedDate || isSameDate(orderDate, new Date(selectedDate));
  
        return searchTermMatch && dateMatch;
      });
  
      const sortedFilteredOrders = filtered.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      
      // Limit to first 15 orders
      setFilteredOrders(sortedFilteredOrders.slice(0, 11));
    } else {
      setFilteredOrders([]);
    }
  }, [searchTerm, selectedDate, myorders]);
  

  const formatIndianDate = (dateString) => {
    const options = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleString('en-IN', options);
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const resetDate = () => {
    setSelectedDate('');
  };

  const handleRowClick = (order) => {
    setSelectedOrder(order);
  };

  const handleCloseOverlay = () => {
    setSelectedOrder(null);
  };

  return (
    <div>
      <AdminNavbar />
      <h2 className="order-heading">Order Management</h2>
      <div className="order-filter-container">
        <input
          className="order-search-bar"
          type="text"
          placeholder="Search by Order ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <input
          className="order-date-input"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          placeholder="Select Date"
        />
        {selectedDate && (
          <button className="order-reset-button" onClick={resetDate}>
            Reset Date
          </button>
        )}
      </div>
      <div className="order-table-container">
        <table className="order-table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Amount</th>
              <th>User Name</th>
              <th>User Email</th>
              <th>User Phone</th>
              <th>Status</th>
              <th>Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              // Render skeletons while loading
              Array.from({ length: 11 }).map((_, index) => (
                <tr key={index}>
                  <td><Skeleton width="100%" /></td>
                  <td><Skeleton width="100%" /></td>
                  <td><Skeleton width="100%" /></td>
                  <td><Skeleton width="100%" /></td>
                  <td><Skeleton width="100%" /></td>
                  <td><Skeleton width="100%" /></td>
                  <td><Skeleton width="100%" /></td>
                  
                </tr>
              ))
            ) : (
              filteredOrders.map((order) => (
                <tr
                  key={order.order_id} 
                  className={`fade-in ${order.order_status === 'success' ? 'success' : 'pending'}`}
                  onClick={() => handleRowClick(order)}
                  style={{ cursor: 'pointer' }}
                >
                  <td>{order.title || 'N/A'}</td>
                  <td>{order.amount || 'N/A'}</td>
                  <td>{order.name || 'N/A'}</td>
                  <td>{order.email || 'N/A'}</td>
                  <td>{order.phone || 'N/A'}</td>
                  <td>{order.order_status || 'N/A'}</td>
                  <td>{order.createdAt ? formatIndianDate(order.createdAt) : 'N/A'}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {selectedOrder && (
        <div className="overlay">
          <div className="overlay-content">
            <span className="close-btn" onClick={handleCloseOverlay}>&times;</span>
            <div>
              <h2>Order Details</h2>
              <h3>User Name: {selectedOrder.name}</h3>
              <h3>User Email: {selectedOrder.email}</h3>
              <h3>User Phone: {selectedOrder.phone}</h3>
              <h4>Product Name: {selectedOrder.title}</h4>
              <div>Order ID: {selectedOrder.order_id}</div>
              <div>User ID: {selectedOrder.userId}</div>
              <div>Total Amount: {selectedOrder.amount}</div>
              <div>Payment Status: {selectedOrder.order_status}</div>
              <div>Date and Time: {selectedOrder.createdAt}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderManagement;
