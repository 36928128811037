import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(''); // ✅ OTP State
  const [otpSent, setOtpSent] = useState(false); // ✅ Show OTP Input if OTP is sent
  const API_URL = 'https://waveart.in/';
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleValue = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}admin/signin`, inputValue);
      if (response.status === 200) {
        setMessage('OTP sent to your email. Please verify.');
        setOtpSent(true); // ✅ Show OTP Input
      } else {
        setMessage("Login failed. Please try again.");
      }
    } catch (error) {
      setMessage('Invalid credentials. Please try again.');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    if (!otp) {
      setMessage("Please enter OTP.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}admin/verify-otp`, {
        email: inputValue.email,
        otp,
      });

      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        setMessage('Login successful!');
        navigate('/AdminPanel');
      } else {
        setMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setMessage('OTP verification failed. Please try again.');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-container">
      <div className="logo-container">
        <img src="/Images/wave_logo.png" alt="Logo" className="logo" />
        <p className="left-text">Welcome to our platform! Please login to continue.</p>
      </div>
      <div className="login-container">
        <h1 className="login-title">Login</h1>
        {!otpSent ? (
          <form onSubmit={handleLoginForm}>
            <div className="form-group">
              <label htmlFor="userId">User ID:</label>
              <input
                type="text"
                id="userId"
                name="username"
                onChange={handleValue}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="Email">Email:</label>
              <input type="email" id="Email" name="email" onChange={handleValue} required />
            </div>

            <div className="form-group">
              <label htmlFor="Password">Password:</label>
              <div className="password-input">
                <input
                  type={showPassword ? "text" : "password"}
                  id="Password"
                  name="password"
                  onChange={handleValue}
                  required
                />
                <button type="button" className="password-toggle-btn" onClick={handleTogglePassword}>
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            <button className="submit-btn" type="submit" disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Login'}
            </button>
          </form>
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="Enter OTP">Enter the OTP:</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
            <button className="submit-btn" onClick={handleOtpVerification} disabled={isLoading}>
              {isLoading ? 'Verifying...' : 'Verify OTP'}
            </button>
          </div>
        )}
        {message && <div className="response">{message}</div>}
      </div>
    </div>
  );
};

export default Login;
